import { GenericItem } from '@homeflow/next/state';

export function getItem(items: GenericItem[], itemIdChain?: string[]) {
  if (itemIdChain === undefined) return null;

  return itemIdChain.reduce(
    (item, id) => {
      const items = item?.items as GenericItem[];
      return items.find((subItem) => subItem.id == id) as GenericItem;
    },
    { items } as GenericItem
  );
}
