'use client';

import { useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CustomFormConfig } from '@homeflow/next/state';
import { SectionProps } from '@homeflow/next/sections';
import { CustomFormParams, FormElement as FormElementType } from '@homeflow/next/models';

import Button from '@rigel/components/buttons/button.component';

import Container from '../../components/container.component';
import SectionElement from '../section-element.component';
import FormElement from './components/form-element.component';
import SendingMessage from '../../components/forms/sending-message.component';
import MessageSent from '../../components/forms/message-sent.component';
import MarketingPreferences from '../../components/forms/marketing-preferences.component';
import { SerializableAgency } from '@/serializers/agency';
import useCustomForm from '@/hooks/use-custom-form';

export default function CustomForm({
  editMode,
  section,
  SectionElementWrapper,
  agency,
}: SectionProps<CustomFormConfig> & { agency: SerializableAgency }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CustomFormParams>();

  const { loadingForms, status, forms, onSubmit, selectedForm } = useCustomForm({ section });

  if (status === 'loading')
    return (
      <div className="[&_p]:text-white">
        <SendingMessage />
      </div>
    );

  if (status === 'success')
    return (
      <div className="[&_p]:text-white">
        <MessageSent />
      </div>
    );

  return (
    <Container containerWidth={section.configuration.containerWidth}>
      <div className="bg-grey-alternative p-10 @lg:p-20">
        {!!section.configuration.elements.length && (
          <div className="w-full mb-8 flex flex-col items-start">
            {section.configuration.elements.map((element, index) => (
              <SectionElement
                key={element.id}
                element={element}
                elementIndex={index}
                section={section}
                baseClassName="capella-contact-form"
                editMode={editMode}
                SectionElementWrapper={SectionElementWrapper}
              />
            ))}
          </div>
        )}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={twMerge(editMode && 'pointer-events-none')}
          data-form-name={selectedForm?.form_name}
        >
          <div className="w-full grid grid-cols-1 @sm:grid-cols-2 gap-y-4 gap-x-6">
            {loadingForms &&
              !forms.length &&
              Array.from({ length: 4 }, (_, i) => i).map((el) => (
                <div key={el} className="h-10 w-full bg-slate-200 animate-pulse mb-4" />
              ))}
            {selectedForm?.form_elements
              .sort((a: FormElementType, b: FormElementType) => a.field_position - b.field_position)
              .map((element, index) => {
                const item = section.configuration?.items?.[index];

                return (
                  <div
                    key={element.field_name}
                    className={twMerge(
                      item && 'fullWidth' in item && item.fullWidth && '@sm:col-span-2'
                    )}
                  >
                    <FormElement register={register} element={element} formId={section.id} />
                  </div>
                );
              })}
          </div>
          <div className="flex justify-center w-full">
            <MarketingPreferences
              className="mb-4"
              registerValidation={register}
              agency={agency}
              errors={errors}
            >
              <Button type="submit" className="w-full @sm:w-fit px-20">
                {section.configuration.link?.html || 'Submit'}
              </Button>
            </MarketingPreferences>
          </div>
        </form>
      </div>
    </Container>
  );
}
// onSubmit={editMode ? handleEditModeSubmit : handleSubmit}
